import React, { useEffect } from "react";
import "./GainAccessToNetwork.scss";
import NewtworkImage from "../../../images/network-image.jpg";
import StyledButton from "../../../components/styledButton";
import AOS from "aos";
export default function GainAccessToNetwork({ handleClick }) {
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
  return (
    <div className="gain-access-to-network-cover">
      <div className="row">
        <div
          className="col-md-5 col-12"
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor-placement="top-center"
        >
          <img src={NewtworkImage} alt="" style={{ width: "100%" }} />
        </div>
        <div
          className="col-md-7 col-12 network-content-cover"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor-placement="top-center"
        >
          <div className="newwork-title-div">
            Gain access to a network of high-intent customers
          </div>
          <div className="network-subtitle-div">
            The Odiopay marketplace delivers a network of high-intent customers
            with the purchasing power that your business deserves.
          </div>

          <StyledButton
            className={"style-button-mobile"}
            text={"Get Started"}
            background={"#233AFF"}
            fontSize={"16px"}
            fontFamily={"sen-bold"}
            width={"161px"}
            height={"65px"}
            border={"none"}
            color={"white"}
            handleClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
}

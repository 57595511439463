import React from "react";
import { useNavigate } from "react-router-dom";
import AppLogo from "../../images/app-logo.svg";
import StyledButton from "../styledButton";
import "./Navbar.scss";
export default function Navbar({ handleClick }) {
  const navigate = useNavigate();
  return (
    <div className="nav-cover">
      <div className="row m-0 p-0">
        <div className="col-7 nav-logo-cover">
          <img
            src={AppLogo}
            alt=""
            className="app-logo"
            style={{ width: "217px", cursor: "pointer" }}
            height={"47px"}
            onClick={() => navigate("/")}
          />
        </div>
        <div className="col-5 nav-button-cover">
          <span className="span-nav-button">
            <StyledButton
              background={"#3654C7"}
              color={"white"}
              width={"186px"}
              height={"51px"}
              text={"Get Started"}
              border={"none"}
              fontSize={"16px"}
              fontFamily={"sen-bold"}
              handleClick={handleClick}
            />
          </span>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Slider1 from "../../images/new-slide-2.jpg";
import Slider2 from "../../images/odio-merchant-hero1.jpg";
import Slider3 from "../../images/odio-merchant-mobile-hero.jpg";
import "./Carousel.scss";
import StyledButton from "../styledButton";
export default function CarouselComp({ handleClick }) {
  return (
    <div>
      <div className="dasktop-carosel">
        <Carousel autoPlay={"2000"} infiniteLoop={true}>
          <div className="carousel-cover">
            <img src={Slider1} alt="" className="slider-actual-image" />
            <div className="slider-caption">
              <div className="slider-caption-title">
                Boost your business with Odiopay
              </div>
              <div className="slider-caption-sub-ttitle">
                Give your customers the freedom to split payments. while you get
                paid in full and upfront.
              </div>
              <div className="carousel-button-div">
                <StyledButton
                  text={"Get Started for Free"}
                  fontSize={"16px"}
                  color={"#35383D"}
                  border={"none"}
                  fontFamily={"sen-bold"}
                  height={"65px"}
                  borderRadius={"5px"}
                  width={"193px"}
                  handleClick={handleClick}
                />
              </div>
            </div>
          </div>

          <div className="carousel-cover">
            <img src={Slider2} alt="" className="slider-actual-image" />
            <div className="slider-caption">
              <div className="slider-caption-title">
                Boost your business with Odiopay
              </div>
              <div className="slider-caption-sub-ttitle">
                Give your customers the freedom to split payments. while you get
                paid in full and upfront.
              </div>
              <div className="carousel-button-div">
                <StyledButton
                  text={"Get Started for Free"}
                  fontSize={"16px"}
                  color={"#35383D"}
                  border={"none"}
                  fontFamily={"sen-bold"}
                  height={"65px"}
                  borderRadius={"5px"}
                  handleClick={handleClick}
                />
              </div>
            </div>
          </div>
        </Carousel>
      </div>
      <div className="mobile-carosel">
        {/* <img src={Slider3} alt="" width={"100%"}/> */}
        <div className="caption-div">
          <div className="caption-title">Boost your business with Odiopay</div>
          <div className="caption-sub-title">
            Give your customers the freedom to split payments. while you get
            paid in full and upfront.
          </div>
          <div className="caption-button-div">
            <StyledButton
              text={"Get Started for Free"}
              fontSize={"16px"}
              color={"#35383D"}
              border={"none"}
              fontFamily={"sen-bold"}
              height={"65px"}
              borderRadius={"5px"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import { useRef } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.scss";

import LandingPage from "./views/LandingPage";
import ThankYou from "./views/ThankYou";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/thank-you" element={<ThankYou />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";

export default function FaqCom() {
    const data = {

        rows: [
            {


                title: "How do I get started with Odiopay?",
                content: `You can get started right now by signing up below (for free!). We’ll collect information about your business and a member of our team will get in touch to assist and onboard you.`,
            },
            {


                title: "How much does it cost?",
                content:
                    "It’s free to integrate Odiopay on your website or in-store. Our team are available to guide you the process and answer any questions you might have.",
            },
            {


                title: "Who does Odiopay work with?",
                content: `Odiopay works with retail partners from all industries. Sign up today - It’s free.`,
            },

        ],
    };
    const styles = {
        // bgColor: 'white',

        rowTitleColor: "#1C1C1B",
        rowContentColor:"black",
        rowTitleTextSize: "24px",

        rowContentTextSize: '18px',
        // arrowColor: "red",
    };

    const config = {
        animate: true,
        // arrowIcon: "V",
        // tabFocus: true
    };
  return (
    <div>
            <Faq
                data={data}
                styles={styles}
                config={config}
            />
        </div>
  )
}

import React, { useEffect } from "react";
import "./WhyChooseOdiopay.scss";
import House from "../../../images/house.svg";
import Money from "../../../images/money.svg";
import Security from "../../../images/security.svg";
import Setting from "../../../images/setting.svg";
import loyalty from "../../../images/loyalty.svg";
import growth from "../../../images/growth.svg";
import StyledButton from "../../../components/styledButton";
import AOS from "aos";
export default function WhyChooseOdiopay({ handleClick }) {
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
  const whyUsContent = [
    {
      id: 1,
      title: "New customers.",
      subTitle:
        "Tap into our valuable network of high-intent customers. We refer shoppers to your store via our marketing campaigns and our shopping app.",
      logo: House,
    },
    {
      id: 2,
      title: " Get paid right away.",
      subTitle:
        "Your business gets paid up front and in full. We take care of the rest. Customers get what they love now, without extra hassle for you.",
      logo: Money,
    },
    {
      id: 3,
      title: "Seller protection.",
      subTitle:
        "We assume all credit and fraud risk when our payment methods are used, so you can focus on your business.",
      logo: Security,
    },
    {
      id: 4,
      title: "Fully customizable.",
      subTitle:
        "Our solutions are tailored to suit your unique needs and designed to effortlessly integrate with—not overpower—your established branding.",
      logo: Setting,
    },
    {
      id: 5,
      title: "Customer loyalty.",
      subTitle:
        "Fortify your loyal customer base by offering them convenient and flexible payment options.",
      logo: loyalty,
    },
    {
      id: 6,
      title: " Fast-track your revenue goals.",
      subTitle:
        "Capture every possible sale when you show your customers the right offer, at the right time, in the right place.",
      logo: growth,
    },
  ];

  return (
    <div className="why-choose-us-cover">
      <div
        className="why-choose-us-title"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
        data-aos-anchor-placement="top-center"
      >
        Why choose Odiopay?
      </div>
      <div className="row why-choose-us-row-cover m-0">
        {whyUsContent?.map((list) => (
          <div
            className="col-lg-4 col-md-6 col-12 why-us-card-outter-cover"
            data-aos="zoom-in"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor-placement="top-center"
          >
            <div className="why-choose-us-card-cover">
              <div className="why-us-logo-div">
                <img
                  src={list?.logo}
                  alt=""
                  style={{ width: "60px", height: "60px" }}
                />
              </div>
              <div className="why-us-title-div">{list?.title}</div>
              <div className="why-us-subtitle-div">{list?.subTitle}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="mobile-why-us-subtitle-div-button">
        <StyledButton
          className={"style-button-mobile"}
          text={"Get Started"}
          background={"#233AFF"}
          fontSize={"16px"}
          fontFamily={"sen-bold"}
          width={"161px"}
          height={"65px"}
          border={"none"}
          color={"white"}
          handleClick={handleClick}
        />
      </div>
    </div>
  );
}

import React from "react";
import { useNavigate } from "react-router-dom";
import StyledButton from "../../components/styledButton";
import "./ThankYou.scss";
import Navbar from "../../components/NavBar";
export default function ThankYou() {
  const navigate = useNavigate();

  return (
    <div>
      <Navbar />
      <div className="thank-you-cover">
        <div className="container thank-you-content-div col-lg-4 col-12">
          <div className="thank-you-title-div">Thank You</div>
          <div className="thank-you-subtitle-div">
            We will get in touch with you shortly to complete sign up
          </div>
          <div className="thank-you-button-div">
            <StyledButton
              text={"Continue"}
              fontSize={"18px"}
              color={"white"}
              background={"#233AFF"}
              border={"none"}
              width={"90%"}
              height={"55px"}
              handleClick={() => navigate("/")}
              fontFamily={"sen-bold"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

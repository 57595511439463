import React, { useEffect } from 'react'
import "./SellingWithOdioPay.scss"
import SellLogo1 from "../../../images/sell-icon-1.svg"
import Arrow1 from "../../../images/Arrow.svg"
import Arrow2 from "../../../images/Arrow-down.svg"
import SellLogo2 from "../../../images/sell-store.svg"
import SellLogo3 from "../../../images/check-all.svg"
import AOS from 'aos';
export default function SellingWithOdioPayCover() {
    useEffect(()=>{
        AOS.init({
            // Global settings:
            disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            initClassName: 'aos-init', // class applied after initialization
            animatedClassName: 'aos-animate', // class applied on animation
            useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
            disableMutationObserver: false, // disables automatic mutations' detections (advanced)
            debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
            throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 120, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 400, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
            anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

          });
    },[])
    const sellingContent=[
        {
            title:"Sign up",subTitle:" Simply sign up as a merchant on Odiopay and tell us about your business.",logo:SellLogo1,arrow:Arrow1,
            top:"20%",left:"40%"

        },
        {
            title:"Setup your store",subTitle:"Setup your store, upload your products and add your bank details for payment.",logo:SellLogo2,arrow:Arrow2,
            paddingTop:"127px",
            top:"0%",left:"40%",

        },
        {
            title:"Get Paid -Upfront",subTitle:"Get paid upfront when a customer orders from your store.",logo:SellLogo3,arrow:""
            , paddingTop:"196px",
            marginBottom:"159"
        },
    ]
  return (
    <div className='selling-with-odiopay-cover'  data-aos="fade-in"
    data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false"
    data-aos-anchor-placement="top-center">
        <div className='selling-with-odiopay-title'   >
        Selling  with<br/> Odiopay is simple.
        </div>
        <div className='row m-0 '>
            {
                sellingContent?.map(list=>    <div className='col-md-4 col-12 overall-card-cover' style={{paddingTop:list?.paddingTop,}}>
                <div className='selling-card-cover'>
                    <div className='selling-card-logo'>
                        <div className="selling-logo-cover"   data-aos="zoom-in"
    data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false"
    data-aos-anchor-placement="top-center" >
                            <img src={list?.logo} alt="" style={{position:"absolute",top:"30%",left:"40%"}}/>
                        </div>
                        <img src={list?.arrow} className='sell-arrow' alt="" style={{position:"absolute",left:list?.left,top:list?.top,width:"222px",zIndex:1}}/>
                        <div className='selling-card-title' >
                            {
                                list?.title
                            }
                        </div>
                        <div className='selling-card-sub-title'>
                            {
                                list?.subTitle
                            }
                       </div>
                    </div>
                </div>
            </div>)
            }



        </div>
    </div>
  )
}

import React, { useEffect } from "react";
import StyledButton from "../../../components/styledButton";
import "./LetOdiopayHelpBanner.scss";
import AOS from "aos";
export default function LetOdiopayHelpBanner({ handleClick }) {
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
  return (
    <div
      className="LetOdiopayHelpCover"
      data-aos="ease-in"
      data-aos-offset="200"
      data-aos-delay="50"
      data-aos-duration="50"
      data-aos-easing="ease-in"
      data-aos-mirror="true"
      data-aos-once="false"
      data-aos-anchor-placement="top-center"
    >
      <div className="LetOdiopayHelp-inner-cover">
        <div className="row m-0">
          <div className="col-md-9 col-7 LetOdiopayHelp-content-div">
            Let Odiopay help you reach your goals
          </div>
          <div className="col-md-3 col-5 LetOdiopayHelp-button-div">
            <div className="LetOdiopayHelp-desktop-button-div">
              <StyledButton
                text={"Get Started for Free"}
                color={"#35383D"}
                background={"white"}
                fontFamily={"sen-bold"}
                borderRadius={"5px"}
                width={"193px"}
                height={"65px"}
                border={"none"}
                handleClick={handleClick}
              />
            </div>

            <div className="LetOdiopayHelp-mobile-button-div">
              <StyledButton
                text={"Get Started"}
                color={"#35383D"}
                background={"white"}
                fontFamily={"sen-bold"}
                borderRadius={"5px"}
                width={"103px"}
                height={"33px"}
                border={"none"}
                fontSize={"12px"}
                handleClick={handleClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from 'react'
import "./LandingPageCard.scss"
import EcommLogo from "../../../images/ecommerce-logo.svg"
import StoreLogo from "../../../images/store-logo.svg"
import LinkLogo from "../../../images/link-logo.svg"
import AOS from 'aos';
export default function LandingPageCard() {
    useEffect(()=>{
        AOS.init({
            // Global settings:
            disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            initClassName: 'aos-init', // class applied after initialization
            animatedClassName: 'aos-animate', // class applied on animation
            useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
            disableMutationObserver: false, // disables automatic mutations' detections (advanced)
            debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
            throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 120, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 300, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
            anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

          });
    },[])
    const cardDetails=[
        {id:1,title:"E-commerce",subtitle:"Integrate Odiopay easily and seamless into your existing e-commerce store",logo:EcommLogo},
        {id:2,title:"In-store",subtitle:"Offer flexible payment options to your customers in-store.",logo:StoreLogo},
        {id:3,title:"Payment link",subtitle:"Request installmental payments from your customers using a link.",logo:LinkLogo},
    ]



  return (
    <div className='landing-page-card-cover' >
        <div className='row landing-page-card-dsktop-view'>
            {
                cardDetails?.map(list=> <div className='col-lg col-md-6 col-12 ipad-margin-bottom' data-aos="zoom-in"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="false"
                data-aos-anchor-placement="top-center">
                <div className='landing-page-inner-card-cover'>
                <div className='landing-page-card-logo'>
                    <img src={list?.logo} alt=""/>
                </div>
                <div className='landing-page-card-title'>
                {list?.title}

                </div>
                <div className='landing-page-card-subtitle'>
                    {list?.subtitle}
                </div>
                </div>
             </div>)
            }

        </div>
        <div className='landing-page-card-mobile-view' data-aos="fade-in"
    data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false"
    data-aos-anchor-placement="top-center" >
        <div className='landing-page-card-mobile-inner-view'>
        {
                cardDetails?.map(list=> <div className='landing-page-outer-card-cover'  >
                <div className='landing-page-inner-card-cover'>
                <div className='landing-page-card-logo'>
                    <img src={list?.logo} alt=""/>
                </div>
                <div className='landing-page-card-title'>
                {list?.title}

                </div>
                <div className='landing-page-card-subtitle'>
                    {list?.subtitle}
                </div>
                </div>
             </div>)
            }
        </div>
        </div>
    </div>
  )
}

import React, { useRef } from "react";
import CarouseComp from "../../components/carousel";
import FooterGetStart from "../FooterGetStart";
import FAQ from "./Faq";
import GainAccessToNetwork from "./GainAccessToNetwork";
import GetInTouch from "./GetInTouch";
import GiveMoreTimeToPay from "./GiveMoreTimeToPay";
import KeepYourCustomerComing from "./KeepYourCustomerComing";
import "./LandingPage.scss";
import LandingPageCard from "./LandingPageCard";
import LetOdiopayHelpBanner from "./LetOdiopayHelpBanner";
import SellingWithOdioPayCover from "./SellingWithOdioPay";
import WhyChooseOdiopay from "./whyChooseodiopay";
// import AOS from 'aos';
import Navbar from "../../components/NavBar";
export default function LandingPage() {
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  return (
    <div>
      <Navbar handleClick={executeScroll} />
      <CarouseComp handleClick={executeScroll} />
      <GiveMoreTimeToPay />
      <LandingPageCard />
      <LetOdiopayHelpBanner handleClick={executeScroll} />
      <KeepYourCustomerComing handleClick={executeScroll} />
      <GainAccessToNetwork handleClick={executeScroll} />
      <WhyChooseOdiopay handleClick={executeScroll} />
      <SellingWithOdioPayCover />
      <LetOdiopayHelpBanner handleClick={executeScroll} />
      <FAQ />
      <div ref={myRef}>
        <GetInTouch />
      </div>

      <FooterGetStart handleClick={executeScroll} />
    </div>
  );
}

import React from "react";
import StyledButton from "../../components/styledButton";
import "./FooterGetStart.scss";
export default function FooterGetStart({ handleClick }) {
  return (
    <div>
      <div className="footer-get-start-cover">
        <div className="footer-get-start-title">
          Are you ready to increase your sales and customer loyalty?
        </div>
        <div className="button-div">
          <StyledButton
            text={"Get Started for Free"}
            color={"black"}
            fontSize={"16px"}
            fontFamily={"sen-bold"}
            border={"none"}
            height={"65px"}
            width={"292px"}
            borderRadius={"5px"}
            handleClick={handleClick}
          />
        </div>
      </div>
      <div
        style={{ background: "black", height: "480px" }}
        className="footer-get-start-cover"
      ></div>
    </div>
  );
}
